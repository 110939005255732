import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import Paper from '@material-ui/core/Paper'
import * as GraphTypes from 'graphql/types/graphql'
import getProducts from 'graphql/queries/products'

import CompareGraphContents from './CompareGraphContents'

import { loggerFn } from 'utils/logger'
import { selectedAccountQuery } from 'graphql/local/selectedAccount'
import AccountDropDownSelector from 'components/account/DropDownSelector/AccountDropDownSelector'
const logger = loggerFn('CompareGraph')

const getProductList = (productList: GraphTypes.ProductList): GraphTypes.Product[] => {
  logger.log("Load products", productList)
  if (!productList) throw Error("No products detected")
  return productList.items as GraphTypes.Product[]
}

const CompareGraph = () => {
  const { loading:loadingR,  error:errorR, data: dataR } = useQuery<GraphTypes.Query>(getProducts, {
    variables: { type: 'savings' }
  }) 
  const { loading, error, data : account } = useQuery<GraphTypes.Query>(selectedAccountQuery)

  if (loading || loadingR) return <p>Loading ...</p>
  if (error) return <p>`Error! ${error.message}`</p>
  if (errorR) return <p>`Error! ${errorR.message}`</p>
  if (!dataR) return <>{ logger.error("BRO YOU SUCK")}</>
  const products = getProductList(dataR.products as GraphTypes.ProductList)
  logger.log("LOADING FOR ACCOUNT", account)
  return (
    <Paper>
      <AccountDropDownSelector />
      { account && <CompareGraphContents account={ (account as any).selectedAccount as GraphTypes.Account } items={ products } /> }
    </Paper>
  )
}

export default CompareGraph