import React from 'react';
import { Input } from '@material-ui/core';
import { loggerFn } from 'utils/logger'
import { parse } from 'utils/product/parse-statement/parse-statement';

const logger = loggerFn("Uploader")

export const Uploader = () => {

    let reader = new FileReader();

    const handleData = (e) => {
        const data = reader.result
        parse(data as string).then(out => logger.log(out))
    }
    const handleFile = (event) => {
        reader.onload = (e) => handleData(e);
        reader.readAsDataURL(event.target.files[0]);
        //reader.readAsText(event.target.files[0]);
    }
    return (
        <Input 
            type="FILE"
            onInput={handleFile}
            />
    );
}
