import b from 'bss'
import * as CommonStyles from 'components/common/CommonStyles' 

export const topLevelAccountClass = b`
  display: grid;
  font-family: arial;
  grid-template-columns: 100px auto 210px;
  column-gap:10px;
  padding:4px;
  color: ${CommonStyles.BlackText};
  border-bottom: 1px solid ${CommonStyles.GreyBorder};
`.class

export const typeClass = {
  savings : b`
      color: ${CommonStyles.BlackText};
      text-transform: capitalize;
      text-weight: bold;
      border-radius: 4px; 
      border:1px solid ${CommonStyles.GreyBorder};
      flex-grow: 2;
      text-align: left;
      display:flex;
      align-items:center;
      font-weight: bold;
      font-size: 14px;
      padding: 2px 8px;
      width: fit-content;
      margin-bottom:12px;
    `.class
}
export const ratings = b`
  font-size: 14px;
  text-align:right;
`.class
export const ratingsLabel = b`
  display:inline;
  text-align:left;
  color: ${CommonStyles.LightGreyText};
`.class

export const BlueText = b`
    color: ${CommonStyles.BlueText};
`.blueText

export const productName = b`
    font-size: 24px; 
`.class
export const businessName = b`
    font-size: 16px; 
    color: ${CommonStyles.LightGreyText};
`.class
export const balance = b`
  font-size: 26px; 
  text-align:right;
  color:  ${CommonStyles.BlackText};
`.class
export const inline = b`
  display:inline-block
`.class
export const productTooltip = b`
  width: 25px
  clear:both;
  margin:10px;
  margin-left:25px;
`.class
export const BottomButton = b`
margin-top: 10px;
`.class

export const padTop = b`
margin-top:8px;
`.class