import React, { useState } from 'react'
import * as GraphQL from 'graphql/types/graphql'


import { loggerFn } from 'utils/logger'
import { calculateInterest } from 'utils/product/calulateRate'
import { Checkbox } from '@material-ui/core'
import CompareGraphGoogle from './CompareGraphGoogle'
const logger = loggerFn('CompareTableContents')

type CompareTableContentsProps = {
    items: GraphQL.Product[]
    account: GraphQL.Account
}

const CompareGraphContents = ({ items, account }:CompareTableContentsProps) => {
    const allItems: Array<any> = []
    const [ period, setPeriod ] = useState(12)
    const [ bonus, setBonus ] = useState(false)
    items.filter(i => i.ProductCategory === "TRANS_AND_SAVINGS_ACCOUNTS").forEach(item => {
        allItems.push({product: item, return: calculateInterest(item, Number(account.balance!), bonus, period)})
    })
    const sortedItems = allItems.sort((a, b) => b.return - a.return)
    const top5average = sortedItems.slice(0, 5).reduce((a, b) => a + b.return, 0) / 5
    const average = sortedItems.reduce((a, b) => a + b.return, 0) / sortedItems.length
    return (
        <div>
            <div><CompareGraphGoogle balance={Number(account.balance!)} top5={top5average} average={average} /></div>
            <div>
                <label htmlFor="time">Time</label>
                <input id="time" value={period} onChange={ (event) => setPeriod(Number(event.target.value))} />
            </div>
            <div>
                <label htmlFor="time">Include bonus interest</label>
                <p>In order to reviece bonus interest payments specific criteria must be met on the account</p>
                <Checkbox id="time" value={bonus} onChange={ (event) => setBonus(event.target.checked)} />
            </div>
        </div>
    )   
}

export default CompareGraphContents
