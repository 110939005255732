import React, { useState, useMemo } from 'react'
import { useAuth0 } from 'components/auth/react-auth0-wrapper';
import { loggerFn } from 'utils/logger';
import { useQuery } from '@apollo/react-hooks';
import * as GraphTypes from 'graphql/types/graphql'
import login from 'graphql/queries/login'

const logger = loggerFn("BasiqPopup")



const BasiqPopup = ({trigger}) => {
    const initPopup = () => {
        if (data){
            const accessToken = (data as any).login
            logger.log("Open for user - ", token!["http://mynance.com/basiqID"])
            const script = document.createElement("script");
            script.text = `var ui = new Basiq({userId: "${token!["http://mynance.com/basiqID"]}", accessToken: "${accessToken}"});`
            setOpen(true)
            logger.log(script)
            document.body.appendChild(script);
        }
    }
    const { token } = useAuth0()
    const [ open, setOpen ] = useState(false)
    const { loading, data } = useQuery<GraphTypes.Query>(login)
    useMemo(initPopup, [loading])
    useMemo(() => {
        if (open && trigger){
            const script = document.createElement("script");
            script.text = `ui.render()`
            document.body.appendChild(script);
        }
    }, [open, trigger])
    if (loading) return <></>
    return <></>
}
 
export default BasiqPopup



