import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import Paper from '@material-ui/core/Paper'
import * as GraphTypes from 'graphql/types/graphql'
import { publicSavings } from 'graphql/local/publicSavings'


import { loggerFn } from 'utils/logger'
import Top5Content from './Top5Content'
const logger = loggerFn('Top5Contents')

const Top5 = () => {
  const { loading, error, data } = useQuery<GraphTypes.Query>(publicSavings) 

  if (loading) return <p>Loading ...</p>
  if (error) return <p>`Error! ${error.message}`</p>
  logger.log((data! as any).publicSavings)
  
  return (
    <Paper>
      <Top5Content items={ (data! as any).publicSavings as Array<GraphTypes.Product> } />
    </Paper>
  )
}

export default Top5
