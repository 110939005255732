import React, { useState } from 'react'
import * as GraphTypes from 'graphql/types/graphql'
import Account from '../Account'
import { loggerFn } from 'utils/logger'

const logger = loggerFn('AccountSelector')

type AccountSelectorProps = {
  accounts: GraphTypes.Account[]
}



const AccountList = ({ accounts }: AccountSelectorProps) => {
  return (
    <>
      { accounts.filter(account => account.class!.type === "savings").map(account => <Account account={account} />) }
    </>
  )
}

export default AccountList
