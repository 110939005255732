import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import * as GraphTypes from 'graphql/types/graphql'
import getAccounts from 'graphql/queries/accounts'
import AccountSelector from './AccountSelector'

import { loggerFn } from 'utils/logger'
const logger = loggerFn('AccountDropDownSelector')

const AccountDropDownSelector = () => {
  const { loading, error, data } = useQuery<GraphTypes.Query>(getAccounts)
  if (loading) return <p>Loading ...</p>
  if (error) return <p>`Error! ${error.message}`</p>
  if (!data) return <>{ logger.error("BRO YOU SUCK")}</>
  const accounts = data.accounts!.data as GraphTypes.Account[]

  return (
    <AccountSelector accounts={ accounts } />
  )
}
 
export default AccountDropDownSelector
