import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import * as GraphTypes from 'graphql/types/graphql'
import AccountDropDownList from './AccountDropDownList'
import { selectedAccountQuery, selectedAccountMutation } from 'graphql/local/selectedAccount'

import { loggerFn } from 'utils/logger'
import ButtonTransparent from 'components/common/ButtonTransparent'
import Account from 'components/account/Account'
const logger = loggerFn('AccountSelector')

type AccountSelectorProps = {
  accounts: GraphTypes.Account[]
}

const AccountSelector = ({ accounts }: AccountSelectorProps) => {
  const [dropdownVisible, setDropdownVisible]   = useState(false)
  const { data: account }                       = useQuery(selectedAccountQuery)
  const [ selectAccount ]                       = useMutation(selectedAccountMutation);

  const handleAccountSelected = (accountNo: String) => {
    const account = accounts.filter(a => a.accountNo === accountNo)
    //if (account.length) handleAccountChanged(account[0], client)
    if (account.length) selectAccount({ variables: { selectedAccount: account[0] } })
    else throw Error("Account ID doesnt match any accounts for this user")
    setDropdownVisible(false)
  }
  const handleAccountSelectClick = () => {
    setDropdownVisible(!dropdownVisible)
  }
  return (
    <>
      <ButtonTransparent onClick={ handleAccountSelectClick }>
        { account ? 
          <Account account={ account.selectedAccount } /> :
          <div>Please select an account...</div>
        }
      </ButtonTransparent>
      { dropdownVisible && <AccountDropDownList accounts={ accounts } onClick={ handleAccountSelected }/> }
    </>
  )
}

export default AccountSelector
