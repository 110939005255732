import React from 'react'
import * as GraphQL from 'graphql/types/graphql'                                                          
import { loggerFn } from 'utils/logger'
import { publicSavings } from 'graphql/local/publicSavings'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { Paper } from '@material-ui/core'
import ProductDetailContent from './ProductDetailContent'
const logger = loggerFn("ProductDetail")

const ProductDetail = () => {
    const { loading, error, data } = useQuery<GraphQL.Query>(publicSavings) 

    const { productID } = useParams()
    if (loading) return <p>Loading ...</p>
    if (error) return <p>`Error!`</p>
    const productList = (data! as any).publicSavings.filter(product => product.ProductID === productID)
    
    if (productList.length) {
        return (
            <Paper>
                <ProductDetailContent product={ productList[0] as GraphQL.Product } items={ (data! as any).publicSavings as Array<GraphQL.Product> } />
            </Paper>
        )
    } else {
        return <div>This product doesnt exist!</div>
    }
}

export default ProductDetail
