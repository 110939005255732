import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Top5 from 'components/catalog/Top5'
import ProductDetail from 'components/catalog/ProductDetail'

const OfflineRoutes = () => (
    <Switch>
        <Container maxWidth="md">
            <Route path="/" exact component={Top5} />
            <Route path="/product/:productID" component={ProductDetail} />
        </Container>
    </Switch> 
)

export default OfflineRoutes