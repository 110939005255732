import React from 'react'
import { Chart } from "react-google-charts";

type CompareGraphGoogleProps = {
    balance: Number
    top5: Number
    average: Number
}

const CompareGraphGoogle = ({balance, top5, average}: CompareGraphGoogleProps) => {

  return (
    <Chart
        width={400}
        height={300}
        chartType="ColumnChart"
        loader={<div>Loading Chart</div>}
        data={[
            ['', 'Total return'],
            ['Current Return', balance],
            ['Average Top 5', top5],
            ['Average in Market', average],
        ]}
    />
  )
}

export default CompareGraphGoogle