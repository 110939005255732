import { Account, Transaction } from 'graphql/types/graphql'
import { loggerFn } from 'utils/logger';
import { boolean } from '@storybook/addon-knobs';


const logger = loggerFn("CommBank parser")

export const commbankSavingsStatement = async (data: Array<Array<string>>): Promise<Account> => {

    let account: Account = {
        id: "123",
    };

    account.accountNo = data[0][24]
    account.balance = data[0][29].replace('$','')
    account.class = {
        product: data[0][35]
    }
    let i, transactions: Array<Transaction> = []
    for (let a = 0; a < data.length; a++) {
        let start = -1
        if (a === 0) i = 55;
        else i = 8;
        for (i; i < data[a].length;){
            if (data[a][i].toLowerCase() === "closing balance") break;
            if (isShortDate(data[a][i])) {
                if (start > -1)
                    transactions.push(parseTransactionRow(data[a].slice(start, i-1)))
                start = i
            }
            i++
        }
        if ( i-1-start > 4 )
            transactions.push(parseTransactionRow(data[a].slice(start, i-1)))
    }
    
    account.transactions = transactions
    return account
}

const isShortDate = (str: string): Boolean => {
    return str.match(/^[0-9][0-9] [A-Z][a-z][a-z]$/) !== null
}

const parseTransactionRow = (row: Array<string>): Transaction => {
    let t:Transaction = {}

    // TODO: translate this into right format...
    t.postDate = row[0]
    let desc = ""
    let i = 1
    while (!row[i].match(/[0-9]*\.[0-9][0-9]/) && !isSpecialChar(row[i])) {
        desc = desc + row[i] + " "
        i++
    }
    t.description = desc
    if (isSpecialChar(row[i])) {
        t.direction = "credit"
        i++
    }
    t.amount = row[i++].replace("$","").replace(",", "")
    if (isSpecialChar(row[i])) {
        t.direction = "debit"
        i++
    }
    t.balance = row[i++].replace("$","").replace(",", "")
    return t
}

const isSpecialChar = (str: string): Boolean => {
    return str === "$" || str === "("
}