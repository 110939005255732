/* eslint-disable react/jsx-props-no-spreading */
import React, { CSSProperties } from 'react'
import PropTypes from 'prop-types'

const baseStyle = (disabled: boolean): CSSProperties => ({
  WebkitAppearance: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  outline: 'none',
  border: 'none',
  fontFamily: 'inherit',
  fontWeight: 'inherit',
  color: 'inherit',
  ...disabled && { opacity: 0.5 },
})

/**
 * Accessible wrapper to use DIV as button. Invokes click event on ENTER keypress
 */
const DivButton = ({
  id,
  children,
  className,
  onClick,
  disabled,
  tabIndex = 0,
  title,
}) => (
  <div
    id={id}
    role="button"
    style={{ ...baseStyle(disabled) }}
    title={title}
    className={className}
    tabIndex={tabIndex}
    onKeyUp={ev => ev.keyCode === 13 && (!disabled && onClick())}
    onClick={() => !disabled && onClick()}
  >{children}
  </div>
)

DivButton.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  tabIndex: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
}

export default DivButton
