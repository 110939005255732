import { selectAccountResolver } from './selectedAccount'
import { loggerFn } from 'utils/logger'
import { publicSavingsResolver } from './publicSavings'
import { selectBalanceResolver, selectPeriodResolver, selectBonusResolver } from './searchCriteria'
const logger = loggerFn("local resolvers")

const localResolvers = {
    Mutation: {
        selectAccount:  selectAccountResolver,
        selectBalance:  selectBalanceResolver,
        selectPeriod:   selectPeriodResolver,
        selectBonus:    selectBonusResolver,
    },
    Query: {
        publicSavings: publicSavingsResolver
    }
}

export default localResolvers