import React, { useState } from "react";
import { loggerFn } from "utils/logger";
import { useMutation } from "@apollo/react-hooks";
import register from "graphql/mutations/register"
import RegisterAccountLoading from "./RegisterAccountLoading";

const logger = loggerFn("Register new user")

const RegisterNewUser = () => {
    const [ newToken, setNewToken ] = useState("")
    const [ registerUser, data ] = useMutation(register)

    logger.log("Start")
    if (!data.called) registerUser()
    if (data.error) throw Error(data.error.message)
    if (data.loading) return <RegisterAccountLoading />
    return (
        <>
            <h1>Welcome to the app and stuff</h1>
            <h4>Blah blah</h4>
            <h4>this app is dope</h4>
        </>
    )
}

export default RegisterNewUser