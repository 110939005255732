import gql from 'graphql-tag'
import { loggerFn } from 'utils/logger'
import axios from 'axios'
const logger = loggerFn('publicSavings')

export const publicSavings = gql`
  {
    publicSavings @client 
  }
`

export const publicSavingsResolver = async (_, vars, { cache }) => {
  const s3File = "https://scout-savings-product-public.s3-ap-southeast-2.amazonaws.com/products.json"
  logger.log("get json from s3 ", s3File)
  const response = await axios.get(s3File)
  const data = { data : response.data }
  cache.writeQuery({ query: publicSavings, data });
  return response.data;
}
