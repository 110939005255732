/* eslint-disable react/prop-types */
import React, { ReactNode } from 'react'
import PropTypes from 'prop-types'
import b from 'bss'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import DivButton from '../DivButton'
import { tombStoneCss } from '../Animation/Tombstone'

export const dropdownWrapper = (width: string, padding: number, shadowOffset = '0px') => b`
  position: absolute;
  clear: both;
  width: ${width};
  box-sizing: border-box;
  z-index: 1002;
  background-color: #fff;
  box-shadow: rgba(0,0,0,0.08) 0px 0px 20px 0px;
  padding: ${padding}px;
  margin-top: 1px;
`.class
const DropDownFlat = ({ children, width = '100%', padding = 4 }) => (
  <div
    role="none"
    className={dropdownWrapper(width, padding)}
  >{children}
  </div>
)

export const dropDownItemsWrapper = b`
  padding: 4px;
  background-color:rgba(0,0,0,0,1);
`.class
const DropDownItemsWrapper = ({ children }) => <div className={dropDownItemsWrapper}>{children}</div>


export const dropDownItems = b`
  max-height: 300px;
  overflow-y: auto;
`.class
const DropDownItems = ({ children }) => <div className={dropDownItems}>{children}</div>

export const itemGroup = b`
  padding:4px;
  border-bottom: solid 1px #eee;
  color:black;
`.class
const DropDownItemGroup = ({ children }) => <div className={itemGroup}>{children}</div>

export const resultItem = (gridTemplate: string) => b`
  display: grid;
  grid-template-columns: ${gridTemplate};
  column-gap:10px;
  padding:4px;
  cursor: pointer;
`.$nest('div', 'overflow:hidden;text-overflow:ellipsis').$nest(':hover', 'color:black').class

type DropDownItemProps = {
  /** Content within search result item */
  children: ReactNode;
  /** grid template CSS property for item layout */
  gridTemplate?: CSSProperties['gridTemplate'];
  /** renders given content as tombstone */
  tombstone?: boolean;
  /** triggers when item clicked */
  onClick?: (...args: unknown[]) => void;
}

const DropDownItem = ({
  children,
  gridTemplate = '100px auto 1fr',
  onClick,
}: DropDownItemProps) => (
  <DivButton className={resultItem(gridTemplate)} onClick={ev => { if (onClick) onClick(ev) }}>
    {children}
  </DivButton>
)


type DropDownTombstoneProps = {
  /** rows to render */
  count: number;
  /** render prop to define tombstone layout */
  tombstone: (css: string) => ReactNode;
}

const DropDownTombstone = ({ count = 10, tombstone }) => (
  <>
      {new Array(count).fill(0).map((_, i) => <React.Fragment key={i}>{tombstone(tombStoneCss)}</React.Fragment>)}
  </>
)

DropDownTombstone.propTypes = {
  count: PropTypes.number.isRequired,
  tombstone: PropTypes.func.isRequired,
}

export {
  DropDownFlat,
  DropDownItemsWrapper,
  DropDownItems,
  DropDownItemGroup,
  DropDownItem,
  DropDownTombstone,
}
