
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

const CompanyLogo = ({  id, name, url, size }) => (
    <a href={`https://${url}`} target="_blank" rel="noopener noreferrer">
        <img src={`//logo.clearbit.com/${id}?size=${size}`} alt={name} />
    </a>
)

export default CompanyLogo
