import * as PdfJs from 'pdfjs-dist/webpack';
import { loggerFn } from 'utils/logger';
import { commbankSavingsStatement } from './commbank'

const logger = loggerFn("statement parser")

export const parse = async (dataURL: string): Promise<any> => {
    const data = await parsePDF(dataURL)
    if (data[0][23] === "Account Number"){
        logger.log("Process CommBank savings statement")
        return commbankSavingsStatement(data)
    }
}

export const parsePDF = (dataURL: string): PdfJs.PDFPromise<any> => {
    return PdfJs.getDocument(dataURL).promise.then(pdf => {
        var maxPages = pdf.numPages;
        var countPromises: any = [];
        for(let i = 1; i <= maxPages; i++) {
            var page = pdf.getPage(i);
            countPromises.push(page.then(page => {
                var textContent = page.getTextContent();
                return textContent.then(function(text){ 
                  return text.items.map(function (s) { return s.str; }); 
                });
            }));
        }
        return Promise.all(countPromises).then(function (texts) {
            return texts;
        });
    })
}