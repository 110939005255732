import React from "react";
import { useAuth0 } from "components/auth/react-auth0-wrapper";

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
  pos: {
    marginBottom: 12,
  },
});


const Profile = () => {
  const { loading, user } = useAuth0();
  const classes = useStyles();

  if (loading || !user) {
    return <div>Loading...</div>;
  }

  return (
    <Card className={classes.card}>
      <CardContent>
        <Avatar alt="Profile" src={user.picture} className={classes.bigAvatar} />
        <Typography variant="h5" component="h2">
          {user.name}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {user.email}
        </Typography>
        <Typography variant="body2" component="p">
          <code>{JSON.stringify(user, null, 2)}</code>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Profile;