/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'

const baseStyle = {
  WebkitAppearance: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  outline: 'none',
  border: 'none',
  fontFamily: 'inherit',
  fontWeight: 'inherit',
  color: 'inherit',
  padding: '0px',
}


/** wraps HTML button to inherit it's standard behaviour but resets styling to enable
 * reuse / customisation where 'clickable' behaviour is desired */
const ButtonTransparent = ({ children, style = {}, ...restProps }) =>
  <button type="button" style={{ ...baseStyle, ...style }} {...restProps}>{children}</button>

ButtonTransparent.propTypes = {
  children: PropTypes.object,
  /** style overrides if required */
  style: PropTypes.object,
}

export default ButtonTransparent
