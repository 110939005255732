import React from 'react'
import { selectedPeriodMutation,selectedBonusMutation,selectedBalanceMutation } from 'graphql/local/searchCriteria'
import { useMutation } from '@apollo/react-hooks'
import { Input, Checkbox } from '@material-ui/core'

const SearchCriteriaInputs = ({ searchCriteria }) => {
    const [ setBalance ]    = useMutation(selectedBalanceMutation) 
    const [ setPeriod ]     = useMutation(selectedPeriodMutation) 
    const [ setBonus ]      = useMutation(selectedBonusMutation) 
    return (
        <div>   
            <div>Balance: <Input value={searchCriteria.balance} onChange={ el => setBalance({ variables: { selectBalance : Number(el.target.value) }}) } /></div>
            <div>Period: <Input value={searchCriteria.period} onChange={ el => setPeriod({ variables: { selectPeriod : Number(el.target.value)}}) } /></div>
            <div>Include Bonus Interest: <Checkbox checked={searchCriteria.bonus} onChange={ el => setBonus({ variables: { selectBonus : el.target.checked }}) } /></div>
        </div>
    )
}

export default SearchCriteriaInputs
