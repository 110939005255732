import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Profile from 'components/profile/Profile'
import CompareGraph from 'components/compare/CompareGraph'
import Container from '@material-ui/core/Container'
import AllAccountsCard from 'components/account/AllAccounts/AllAccountsCard'
import { Uploader } from 'components/uploader/Upload'


const Routes = () => (
    <Switch>
        <Container maxWidth="md">
            <Route path="/" exact component={AllAccountsCard} />
            <Route path="/upload" exact component={Uploader} />
            <Route path="/compare" exact component={CompareGraph} />
            <Route path="/profile" component={Profile} />   
        </Container>
    </Switch> 
)

export default Routes