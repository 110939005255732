/* eslint-disable no-loop-func */

import { loggerFn } from 'utils/logger'
import { Product, ProductDepositRate } from 'graphql/types/graphql'
const logger = loggerFn("calculateRate")

type RateObject = {
    variables : number
    intro: number
    bonus: number
}
/*
 *  calculateInterest - Will return the final balance for a specific product given a fixed 
 *                      investment period. Can an account age to project assuming this account is not new.
 *                      Can specify if bonus interest should be taken into account.
 */ 

export const calculateInterest = (product: Product, balance: number, withBonus = false, period = 12, accountAge = 0): [number, RateObject] => {
    if (!product.DepositRates) return [ balance, {variables : 0,intro: 0,bonus: 0} ]
    let total = balance
    const variables = product.DepositRates.filter(rate => rate!.DepositRateType === "VARIABLE")
    const intro = product.DepositRates.filter(rate => rate!.DepositRateType === "INTRODUCTORY")
    const bonus = product.DepositRates.filter(rate => rate!.DepositRateType === "BONUS")
    let bonusRunningTotal:Array<number> = [], introRunningTotal:Array<number> = [], variablesRunningTotal:Array<number> = [], runningFees:Array<number> = []
    for(let i = 0; i < period; i++) {

        const bonusTotal = withBonus ? bonus!.map(item => getMonthInterestTotal(item!, total)).reduce((a, b) => a + b, 0)! : 0
        const monthlyTotal = variables!.map(item => getMonthInterestTotal(item!, total)).reduce((a, b) => a + b, 0)!;
        const introTotal = intro!.map(item => getMonthIntroInterestTotal(item!, total, (accountAge+i))).reduce((a, b) => a + b, 0)!;

        bonusRunningTotal.push((bonusTotal / total) * 12)
        introRunningTotal.push((introTotal / total) * 12)
        variablesRunningTotal.push((monthlyTotal / total) * 12)

        const fees = calcuateMonthlyFees(product)

        runningFees.push(fees)
        total = total + introTotal + monthlyTotal + bonusTotal - fees
    };
    const rates = {
        variables : variablesRunningTotal.reduce((a, b) => a+b) / variablesRunningTotal.length,
        intro: introRunningTotal.reduce((a, b) => a+b) / introRunningTotal.length,
        bonus: bonusRunningTotal.reduce((a, b) => a+b) / bonusRunningTotal.length
    }

    return [total, rates]
}

export const calcuateMonthlyFees = (product: Product): number => {
    if (!product.Fees) return 0
    return product.Fees.filter(i => i!.FeeType==="PERIODIC").reduce((a,b) => a + Number(b!.Amount!), 0)
}

export const getMonthIntroInterestTotal = (rate: ProductDepositRate, balance: number, age: number): number => {
    if (!rate.AdditionalValue) throw Error("Length of introductory is missing from product data")
    const introLength = Number(rate.AdditionalValue.replace("P", "").replace("M",""))
    if (age > introLength) return 0
    if (rate!.Tiers && rate!.Tiers.length) return getMonthInterestTotalForTier(rate, balance)
    return (Number(rate.Rate)/12) * balance
}

export const getMonthInterestTotal = (rate: ProductDepositRate, balance: number): number => {
    if (rate!.Tiers && rate!.Tiers.length) return getMonthInterestTotalForTier(rate, balance)
    return (Number(rate.Rate)/12) * balance
}
  
export const getMonthInterestTotalForTier = (rate: ProductDepositRate, balance: number): number => {
    const min = Number(rate!.Tiers![0]!.MinimumValue)
    const max = Number(rate!.Tiers![0]!.MaximumValue)
    if (min > balance) return 0
    if (max < balance && max !== 0) return (max - min) * (Number(rate.Rate)/12)
    return (balance - min)  * (Number(rate.Rate)/12)
}
