import React, { useState } from 'react'
import * as GraphQL from 'graphql/types/graphql'                                                          
import { loggerFn } from 'utils/logger'
import * as css from './catalog.styles'
import { calculateInterest } from 'utils/product/calulateRate'
import { ProductItems } from 'utils/product/specialTypes'
import { useQuery } from '@apollo/react-hooks'
import { selectedBonusQuery,  selectedBalanceQuery,  selectedPeriodQuery, } from 'graphql/local/searchCriteria'
import { ordinalSuffixOf } from 'utils/render'
import SearchCriteriaInputs from './SearchCriteriaInputs'
import { Link } from 'react-router-dom'
import Chart from 'react-google-charts'
import { calcRate, calcTotal } from 'utils/render'
import BonusRateDetails from './BonusRateDetails'
import CompanyLogo from 'components/common/CompanyLogo'
import Modal from 'components/subscribe/modal'
import { Button } from '@material-ui/core'

type ProductDetailContentProps = {
    product : GraphQL.Product,
    items : Array<GraphQL.Product>,
}

const logger = loggerFn("ProductDetailContent")

const ProductDetailContent = ({product, items}: ProductDetailContentProps) => {
    // Determine search criteria
    const { loading: bonusLoading, error: bonusError, data: bonusData } = useQuery<GraphQL.Query>(selectedBonusQuery) 
    const { loading: balanceLoading, error: balanceError, data: balanceData } = useQuery<GraphQL.Query>(selectedBalanceQuery) 
    const { loading: periodLoading, error: periodError, data: periodData } = useQuery<GraphQL.Query>(selectedPeriodQuery) 

    const [ subscribe, setSubscribe ] = useState(false)
    if (bonusLoading || balanceLoading || periodLoading) return <p>Loading ...</p>
    if (bonusError || balanceError || periodError) return <p>`Error!`</p>

    const searchCriteria = {
      bonus: bonusData ? (bonusData as any).selectedBonus : true,
      balance: balanceData ? (balanceData as any).selectedBalance : 10000,
      period: periodData ? (periodData as any).selectedPeriod : 12,
    }

    const allItems: Array<ProductItems> = []

    items.forEach(item => {
        const [total, rates] = calculateInterest(item, Number(searchCriteria.balance), searchCriteria.bonus, searchCriteria.period)
        allItems.push({product: item, interest: { total: total - searchCriteria.balance, rate: rates }})
    })
    const sortedItems = allItems.sort((a, b) => b.interest.total - a.interest.total)
    const thisItemIndex = sortedItems.findIndex(item => item.product.ProductID === product.ProductID)
    const prevProduct: GraphQL.Product | Boolean = thisItemIndex ? sortedItems[thisItemIndex - 1].product : false
    const nextProduct: GraphQL.Product | Boolean = thisItemIndex !== sortedItems.length - 1 ? sortedItems[thisItemIndex + 1].product : false
    const averageInterest = sortedItems.map(item => item.interest.total).reduce((a,b) => a+b, 0) / sortedItems.length
    const bonusRates = product.DepositRates!.filter(rate => rate!.DepositRateType === "BONUS")
    const biz = product.Business! as any
    return (
        <div>
            { prevProduct && <Link to={`/product/${(prevProduct as GraphQL.Product).ProductID}`}> GO PREV </Link>}
            { nextProduct && <Link to={`/product/${(nextProduct as GraphQL.Product).ProductID}`}> GO NEXT </Link>}
            <div>
                <div>{ ordinalSuffixOf(thisItemIndex + 1) }</div>
                <div><CompanyLogo id={biz.URL} name={biz.Name} size="70" url={biz.URL} /></div>
                <div>{ biz.Name }</div>
                <div>{ product.Name }</div>
            </div>
            <Chart
                width={400}
                height={300}
                chartType="ColumnChart"
                loader={<div>Loading Chart</div>}
                data={[
                    ['', 'Total return'],
                    ['Product Return', sortedItems[thisItemIndex].interest.total],
                    ['Average in Market', averageInterest],
                ]}
            />
            <div>
                <span>{calcRate(sortedItems[thisItemIndex].interest.rate.variables) } (standard)</span>
                { sortedItems[thisItemIndex].interest.rate.intro !== 0 && <span> + {calcRate(sortedItems[thisItemIndex].interest.rate.intro) } (introductory) </span> }
                { sortedItems[thisItemIndex].interest.rate.bonus !== 0 && <span> + {calcRate(sortedItems[thisItemIndex].interest.rate.bonus) } (bonus) </span> }
            </div>
            { bonusRates.length && <BonusRateDetails rates={bonusRates} /> }
            { product.AdditionalInformation && product.AdditionalInformation.OverviewURI && <div>
                <a href={product.AdditionalInformation.OverviewURI} rel="noopener noreferrer" target="_blank">More Info</a>
            </div> }
            <SearchCriteriaInputs searchCriteria={searchCriteria} />
            <Button onClick={ () => setSubscribe(true) }>Compare My Account</Button>
            { subscribe && <Modal handleClose={ () => setSubscribe(false) } /> }
        </div>
    )
}

export default ProductDetailContent
