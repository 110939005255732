import React, { useState } from 'react'
import * as GraphTypes from 'graphql/types/graphql'
import CompanyLogo from 'components/common/CompanyLogo'

import { loggerFn } from 'utils/logger'
import * as css from './Account.styles'
import ProductToolTip from 'components/common/ProductToolTip'
import { Tooltip } from '@material-ui/core'
import { PercentageDiff, productCategoryMap } from 'utils/render'
import { PositiveRateClass, NegativeRateClass, CallToActionButtonClass } from 'components/common/CommonStyles'
import { useMutation } from '@apollo/react-hooks'
import { selectedAccountMutation } from 'graphql/local/selectedAccount'
import { Redirect } from 'react-router-dom'
import { calculateInterest } from 'utils/product/calulateRate'

const logger = loggerFn('AccountCard')

type AccountProps = {
  account: GraphTypes.Account
}

const Account = ({ account }: AccountProps) => {
    const [ redirect, setRedirect ]     = useState<null | string>(null)
    const [ selectAccount ]             = useMutation(selectedAccountMutation);
    const gotoComparePage = () => {
        selectAccount({ variables: { selectedAccount: account } })
        setRedirect("/compare")
    }

    // const twelveMonthReturn = calculateInterest(account.product, Number(account.balance!), false, 12, 16)

    //const [ diffStr, bigger ]           = PercentageDiff(account.product.ProductCategoryStats!.rate!, account.product.MonthlyRate!)
    //const [ scoreDiffStr, scoreBigger ] = PercentageDiff(account.product.Business!.categoryStats!.score!, account.product.Business!.score!)
    
    return (
        <div key={account.id}>
            { redirect && <Redirect to={ redirect } /> }
            <div className={css.topLevelAccountClass}>
                <div>
                    { account.class!.type }
                </div>
                <div>
                    <div className={css.inline}>
                        <div className={css.productName}>{account.name}</div>
                    </div>
                    <div className={ css.BottomButton }>
                        <div className={ CallToActionButtonClass } onClick={ gotoComparePage }>
                            Compare
                        </div>
                    </div>
                </div>
                <div>
                    <div className={css.balance}>${account.balance}</div>
                </div>
            </div>
        </div>
  )
}

export default Account
