import gql from 'graphql-tag'

export default gql`
  query products($category: String) {
    products(category:$category) {
      items {
        id
        ProductCategory
        ProductCategoryStats {
          rate
        }
        BusinessID
        Business {
          id
          name
          url 
          score
          category
          categoryStats{
              score
          }
          __typename
        }
        DepositRates {
          DepositRateType
          Rate
          CalculationFrequency
          AdditionalInfo
          AdditionalValue
          Tiers {
            Name
            UnitOfMeasure
            MinimumValue
            MaximumValue
            RateApplicationMethod
          }
        }
        ProductID
        Name
        ApplicationURI
        Description
        __typename
      }
    }
  }
`