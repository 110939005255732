import React from 'react'
import { Paper, Button } from '@material-ui/core'
import MailchimpSubscribe from "react-mailchimp-subscribe"

const Modal = ({ handleClose }) => {
    const url = "https://gmail.us8.list-manage.com/subscribe/post?u=9116e171f7c4b9f70763f0d6c&id=853c5055c0"

    // simplest form (only email)
    const SimpleForm = () => <MailchimpSubscribe url={url}/>
  
    return (
        <Paper>
            <div>Brah, check out this more of this hot hot shit yarl. Slam jam yo email below and submit that trash forever.</div>
            <div>{ SimpleForm() }</div>
            <Button onClick={handleClose}>X</Button>
        </Paper>
    )
}

export default Modal