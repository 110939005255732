import React from 'react'
import * as GraphTypes from 'graphql/types/graphql'

import * as css from './AccountSearch.styles'
import { loggerFn } from 'utils/logger'
import { DropDownFlat, DropDownItems, DropDownItem } from 'components/common/DropDownFlat'
import { productCategoryMap } from 'utils/render'
const logger = loggerFn('AccountDropDownList')

type AccountDropDownListProps = {
    accounts: GraphTypes.Account[]
    onClick: (accountID: String) => void
}

const AccountDropDownList = ({ accounts, onClick }: AccountDropDownListProps) => {
    logger.log("Loading")
    return (
        <DropDownFlat padding={0}>
            <DropDownItems>
                {accounts && accounts.map(account => {
                    if (!account.product) return <></>
                    return (
                        <DropDownItem
                            gridTemplate="auto 300px "
                            key={account.id}
                            onClick={() => onClick(account.accountNo!)}
                        >
                            <div className={css.iconContainer}>
                                <div>{account.name}</div>
                                <div>{productCategoryMap[account.product!.ProductCategory]}</div>
                            </div>
                            <div className={css.portfolioDetails}>${account.balance}</div>
                        </DropDownItem>
                    )
                }) }
            </DropDownItems>
        </DropDownFlat>
    )
}

export default AccountDropDownList
