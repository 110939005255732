import gql from 'graphql-tag'
import { loggerFn } from 'utils/logger'
const logger = loggerFn('selectedAccountResolver')

export const selectedAccountQuery = gql`
  {
    selectedAccount @client
  }
`

export const selectedAccountMutation = gql`
  mutation selectAccount($selectedAccount: String!) {
    selectAccount(selectedAccount: $selectedAccount) @client
  }
`

export const selectAccountResolver = (_, { selectedAccount }, { cache }) => {
  logger.log("updating resolver to ", selectedAccount)
  const data = { selectedAccount: selectedAccount }
  cache.writeQuery({ query: selectedAccountQuery, data });
  return data.selectedAccount;
}
