import gql from 'graphql-tag'

export default gql`
  query accounts {
    accounts {
      data {
        balance
        product {
          id
          ProductCategory
          ProductCategoryStats {
            rate
          }
          BusinessID
          Business {
            id
            name
            url 
            score
            category
            categoryStats{
                score
            }
            __typename
          }
          DepositRates {
            DepositRateType
            Rate
            CalculationFrequency
            AdditionalInfo
            AdditionalValue
            Tiers {
              Name
              UnitOfMeasure
              MinimumValue
              MaximumValue
              RateApplicationMethod
            }
          }
          ProductID
          Name
          ApplicationURI
          Description
          __typename
        }
        accountNo
        name
        currency
        balance
        availableFunds
        class {
          product
	        type
        }
        connection
      }
    }
  }
`
