import React, { useState } from 'react'
import * as GraphQL from 'graphql/types/graphql'
import { calculateInterest } from 'utils/product/calulateRate'
import ProductView from './ProductView'
import { loggerFn } from 'utils/logger'
import { Button } from '@material-ui/core'
import { ProductItems } from 'utils/product/specialTypes'
import { useMutation, useQuery } from '@apollo/react-hooks'
import {  selectedBonusQuery,  selectedBalanceQuery,  selectedPeriodQuery, } from 'graphql/local/searchCriteria'
import SearchCriteriaInputs from './SearchCriteriaInputs'
const logger = loggerFn("Top5Contents")

type CompareTableContentsProps = {
  items: GraphQL.Product[]
}

const Top5Content = ({ items }:CompareTableContentsProps) => {
  const allItems: Array<ProductItems> = []
  const [ showMore, setShowMore ] = useState(false) 

  // Determine search criteria
  const { loading: bonusLoading, error: bonusError, data: bonusData } = useQuery<GraphQL.Query>(selectedBonusQuery) 
  const { loading: balanceLoading, error: balanceError, data: balanceData } = useQuery<GraphQL.Query>(selectedBalanceQuery) 
  const { loading: periodLoading, error: periodError, data: periodData } = useQuery<GraphQL.Query>(selectedPeriodQuery) 
  if (bonusLoading || balanceLoading || periodLoading) return <p>Loading ...</p>
  if (bonusError || balanceError || periodError) return <p>`Error!`</p>
  const searchCriteria = {
    bonus: bonusData ? (bonusData as any).selectedBonus : true,
    balance: balanceData ? (balanceData as any).selectedBalance : 10000,
    period: periodData ? (periodData as any).selectedPeriod : 12,
  }

  items.forEach(item => {
    const [total, rates] = calculateInterest(item, Number(searchCriteria.balance), searchCriteria.bonus, searchCriteria.period)
    allItems.push({product: item, interest: { total: total - searchCriteria.balance, rate: rates }})
  })
  const sortedItems = allItems.sort((a, b) => b.interest.total - a.interest.total)
  const top5 = showMore ? sortedItems : sortedItems.slice(0, 5)

  logger.log(top5)
  return (
    <div>
      <SearchCriteriaInputs searchCriteria={searchCriteria} />
      <div>
        { top5.map(productObj => <ProductView key={productObj.product.Name!} product={ productObj.product } interest={productObj.interest} />) }
      </div>
      { !showMore && <Button onClick={() => setShowMore(true) } >Show More</Button> }
    </div>
  )   
}

export default Top5Content
