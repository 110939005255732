import React from 'react'

const BonusRateDetails = ({ rates }) => {
  return (
    <div>
        <div>Bonus Rate Eligibility</div>
        <div>{ rates[0].AdditionalValue }</div>
    </div>
  )
}

export default BonusRateDetails
