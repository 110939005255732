import React from 'react'
import * as GraphQL from 'graphql/types/graphql'
import * as css from './catalog.styles'                                                             
import { loggerFn } from 'utils/logger'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { calcRate, calcTotal } from 'utils/render'
const logger = loggerFn("ProductView")

type ProductViewProps = {
  product: GraphQL.Product,
  interest: {
    total: number,
    rate: {
      variables : number
      intro: number
      bonus: number
    }
  } 
}
const ProductView = ( { product, interest }:ProductViewProps) => {
  return (
    <Link to={`/product/${product.ProductID}`} className={css.topLevel}>
      <div>
        <div>{ product.BrandName }</div>
        <div>{ product.Name }</div>
      </div>
      <div>
        <div>+ ${ calcTotal(interest.total) }</div>
        <div>
          <span>{calcRate(interest.rate.variables) } (standard)</span>
          { interest.rate.intro !== 0 && <span> + {calcRate(interest.rate.intro) } (introductory) </span> }
          { interest.rate.bonus !== 0 && <span> + {calcRate(interest.rate.bonus) } (bonus) </span> }
        </div>
      </div>
    </Link>
  )   
}

export default ProductView
