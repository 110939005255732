import React from "react";

const RegisterAccountLoading = () => {

    return (
        <>
            <h1>Some Loading page</h1>
        </>
    )
}

export default RegisterAccountLoading