import gql from 'graphql-tag'
import { loggerFn } from 'utils/logger'
const logger = loggerFn('searchCriteria')

// BALANCE 

export const selectedBalanceQuery = gql`
  {
    selectedBalance @client
  }
`

export const selectedBalanceMutation = gql`
  mutation selectBalance($selectedBalance: String!) {
    selectBalance(selectBalance: $selectBalance) @client
  }
`

export const selectBalanceResolver = (_, { selectBalance }, { cache }) => {
  logger.log("updating balance to ", selectBalance)
  const data = { selectedBalance: selectBalance }
  cache.writeQuery({ query: selectedBalanceQuery, data });
  return data.selectedBalance;
}

// PERIOD 

export const selectedPeriodQuery = gql`
  {
    selectedPeriod @client
  }
`

export const selectedPeriodMutation = gql`
  mutation selectPeriod($selectedPeriod: String!) {
    selectPeriod(selectPeriod: $selectPeriod) @client
  }
`

export const selectPeriodResolver = (_, { selectPeriod }, { cache }) => {
  logger.log("updating Period to ", selectPeriod)
  const data = { selectedPeriod: selectPeriod }
  cache.writeQuery({ query: selectedPeriodQuery, data });
  return data.selectedPeriod;
}

// BONUS 

export const selectedBonusQuery = gql`
  {
    selectedBonus @client
  }
`

export const selectedBonusMutation = gql`
  mutation selectBonus($selectedBonus: String!) {
    selectBonus(selectBonus: $selectBonus) @client
  }
`

export const selectBonusResolver = (_, { selectBonus }, { cache }) => {
  logger.log("updating Bonus to ", selectBonus)
  const data = { selectedBonus: selectBonus }
  cache.writeQuery({ query: selectedBonusQuery, data });
  return data.selectedBonus;
}
