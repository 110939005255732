// enable this to get logging in Jest output. Expect a spray
const logInJestTestRunner = false

const transport = console
const css = (color: string) => `color:white;background-color:${color};padding:2px 4px;border-radius:2px;`
const randomColor = () => `hsl(${Math.random() * 360},70%,40%)`

const canLog = () => true

// const canLog = () => (logInJestTestRunner && process.env.JEST_WORKER_ID)
//   || (window && 'storybook_ui_dll' in window)
//   || (window && '__STORYBOOK_ADDONS' in window)

/**
 * Self-deactivating console logger outside of dev/int
 *
 * @param name label you want to appear in front of all log statements
 * @param disable set this if you want to temporarily disable all log statements in a file
 * @param color manual color for log enty if required, otherwise automatically randomised
 */
export const loggerFn = (name: string, disable = false, color = randomColor()) => ({
  log: (...args:any) => canLog() && !disable && transport.log(...[`%c${name}`, css(color), ...args]),
  debug: (...args:any) => canLog() && !disable && transport.debug(...[`%c${name}`, css(color), ...args]),
  warn: (...args:any) => canLog() && !disable && transport.warn(...[`%c${name}`, css(color), ...args]),
  error: (...args:any) => canLog() && !disable && transport.error(...[`%c${name}`, css(color), ...args]),
})
