import b from 'bss'
import * as CommonStyles from 'components/common/CommonStyles' 

export const topLevel = b`
  text-decoration: none;
  display: grid;
  font-family: arial;
  grid-template-columns: auto 400px;
  column-gap:10px;
  width:100%;
  padding:4px;
  color: ${CommonStyles.BlackText};
  border-bottom: 1px solid ${CommonStyles.GreyBorder};
`.$hover(`
  background-color: ${CommonStyles.GreyBorder};
`)
.class