import b from "bss"

export const BlueText = '#146eb4'
export const GreyText = '#6a7070'
export const LightGreyText = 'rgb(0, 0, 0, 0.5)'
export const BlackText = 'rgb(0, 0, 0, 0.8)'
export const GreyBorder = 'rgb(0, 0, 0, 0.1)'


export const PositiveRateClass = b`
    color: green
`.class
export const NegativeRateClass = b`
    color: red
`.class

export const CallToActionButtonClass = b`
    color: #fff;
    background-color: #007791;
    border: 1px solid transparent;  
    display: inline-block;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    white-space: nowrap;
    padding: 16px 12px;
    font-size: 15px;
    line-height: 1.35135;
    border-radius: 2px;
`.class

export const StandardCardClass = b`

`.class