export const PercentageDiff = (rate1: string, rate2: string ) => {
    const str = Number(rate1) - Number(rate2) > 0 ? '+ ' : ''
    return [
        str + (Math.round((Number(rate1) - Number(rate2)) * 10000) / 10000) + "%",
        Number(rate1) < Number(rate2)
    ]
}

export const ordinalSuffixOf = (i) => {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

export const calcTotal = (num:number): number => {
    return Math.round((num + Number.EPSILON) * 100) / 100
}

export const calcRate = (num:number): number => {
    return Math.round((num + Number.EPSILON) * 10000) / 100
}
  

export const productCategoryMap = {
    "TRANS_AND_SAVINGS_ACCOUNTS" : "Savings"
}