import React from 'react';
import { Link } from "react-router-dom";
import { useAuth0 } from "../auth/react-auth0-wrapper";

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: "30px"
  },
  barLeft: {
    flexGrow: 1
  },
  link: {
    color: "white",
    textDecoration: "none"
  }
}));

const NavBar = () => {
  const { isAuthenticated, logout } = useAuth0();
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar>
        {isAuthenticated && (
          <Typography className={classes.barLeft}>
            <Button><Link to="/" className={classes.link}>Home</Link></Button>
            <Button><Link to="/compare" className={classes.link}>Compare</Link></Button>
          </Typography>
        )}
        <Button color="inherit" onClick={() => logout()}>Log out</Button>
      </Toolbar>
    </AppBar >
  );
}

export default NavBar;
