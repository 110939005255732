import React from 'react'

const NoAccountsYet = () => {
 
  return (
    <div>
        <div>You are yet to configure any accounts</div>
        <div>Click here to get started</div>
    </div>
  )
}
 
export default NoAccountsYet
