import React from 'react'
import b from 'bss'

const colorRotation = b.$keyframes({
  '0%': b`color: #ddd; background-color: #ddd;`.style,
  '50%': b`color: #eee; background-color: #eee;`.style,
  '100%': b`color: #ddd; background-color: #ddd;`.style,
})

export const tombStoneCss = b`
  display: inline-block;
  fontSize: 11px;
  line-height:11px;
  color: #ddd;
  background-color: #ddd;
  border-radius: 2px;
  border: solid 1px transparent;
  margin-bottom: 3px;
`.animation(colorRotation + ' ease-in-out infinite 1.3s').class

export const Tombstone = ({ size }: { size: number }) => (
  <span className={tombStoneCss}>
    {new Array(size).fill('_').join('')}
  </span>
)
