import React, { useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import ApolloClient from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { ApolloProvider } from '@apollo/react-hooks'
import { createAuthLink, AuthOptions, AUTH_TYPE } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import LoginScreen from 'components/auth/LoginScreen'
import appSyncConfig from 'config/aws-exports'
import { useAuth0 } from 'components/auth/react-auth0-wrapper'
import NavBar from 'components/core/NavBar'
import Routes from 'components/core/Routes'
import localResolvers from 'graphql/local/localResolvers'
import { loggerFn } from 'utils/logger'
import AuthenticationHandler from 'components/auth/AuthenticationHandler'
import RegisterNewUser from 'components/auth/RegisterNewUser'
import OfflineRoutes from 'components/core/OfflineRoutes'

function App() {
  const { getIdTokenClaims, loading, isAuthenticated, token } = useAuth0()
  if (loading) {
    return <>Loading...</>
  }

  // AppSync Config with Authentication
  const url = appSyncConfig.graphqlEndpoint
  const region = appSyncConfig.region
  const auth: AuthOptions = {
    type: AUTH_TYPE.OPENID_CONNECT,
    jwtToken: async (): Promise<string> => {
      const token = (await getIdTokenClaims()) || ''
      return token.__raw
    }
  }

  // ApolloClient with AppSync Config
  const httpLink = createHttpLink({ uri: url })

  const link = ApolloLink.from([createAuthLink({ url, region, auth }), createSubscriptionHandshakeLink(url, httpLink)])

  // Required for type resolution
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types: []
      }
    }
  })
  
  const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache({
      fragmentMatcher
    }),
    connectToDevTools: true,
    resolvers: localResolvers,
  })

  if (isAuthenticated) {
      return (
        <div className="App">
          <ApolloProvider client={apolloClient}>
            { !token && <div>whats thios styuif</div> }
            { !(token as any)["http://mynance.com/basiqID"] ? 
                <RegisterNewUser /> :
                <BrowserRouter>
                  <header>
                    <NavBar />
                  </header>
                  <Routes /> 
                </BrowserRouter> }
          </ApolloProvider>
        </div>
      )
  }
  else return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <OfflineRoutes />
      </BrowserRouter>
    </ApolloProvider>
  )
}

export default App
