import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import * as GraphTypes from 'graphql/types/graphql'
import getAccounts from 'graphql/queries/accounts'
import AccountList from './AccountList'

import { loggerFn } from 'utils/logger'
import { StandardCardClass } from 'components/common/CommonStyles'
import NoAccountsYet from './NoAccountsYet'
import { Button } from '@material-ui/core'
import BasiqPopup from './BasiqPopup'
const logger = loggerFn('AccountDropDownSelector')

const AllAccountsCard = () => {
  const { loading, error, data } = useQuery<GraphTypes.Query>(getAccounts)
  let [ newAccount, setNewAccount ] = useState(0)
  if (loading) return <p>Loading ...</p>
  if (error) return <p>`Error! ${error.message}`</p>
  if (!data) return <>{ logger.error("BRO YOU SUCK")}</>
  const accounts = data.accounts!.data as GraphTypes.Account[]

  return (
    <div className={StandardCardClass}>
      <div><Button onClick={() => setNewAccount(newAccount + 1)}>+</Button></div>
      { accounts.length ? <AccountList accounts={ accounts } /> : <NoAccountsYet /> }
      { newAccount!==0 && <BasiqPopup trigger={newAccount} /> }
    </div>
  )
}
 
export default AllAccountsCard
