import b from 'bss'

export const selectedAccountClassName = b`
  color: black;
  flex-grow: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding:4px;
  padding-left: 0px;
  min-width: 300px;
`.class

export const selectedAccountTextClassName = b`
  flex-grow: 2;
  text-align: left;
  display:flex;
  align-items:center;
`.class

export const selectedAccountLabelClassName = b`
  display: inline-block;  
  max-width:350px;
  overflow-x: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  margin-right:5px;
`.class

export const selectedAccountExtraClassName = b`
  color: rgba(0, 0, 0, 0.5);
  display: inline-block;
`.class

export const iconContainer = b`
  width: 100px;
  display: inline-block;
`.class
export const portfolioDetails = b`
  color: rgba(0, 0, 0, 0.85);
  display: inline-block;
  font-weight: 500;
`.class
export const greyText = b`
  color: rgba(0, 0, 0, 0.5);
`.class
export const spendableCash = b`
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  display: inline-block;
`.class
export const searchBox = b`
  outline: 0;
  border:0
  padding:15px 0;
  font-size:15px;
  width: 75%;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 9px;
`.class
export const searchConatiner = b`
  padding-left: 15px;
`.class

export const dropDownHeader = b`
  padding: 11px 17px;
  border-top: 1px solid #ECEDEF;
  border-bottom: 1px solid #ECEDEF;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
`.class
